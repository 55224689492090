<template>
  <v-container>
    <v-row  color="white" class="my_row">
      <v-col style="margin: 0 auto;" sm="12" md="6" cols="12" class="screen_small pr-0">
        <v-card
            color="white"
            class="pa-10 rounded-0 no_border wrapper_form"
            height="365px"
        >
          <h1 style="padding:15px;" class="mb-6 line_40">{{ $t("please_sign_in") }}</h1>
          <v-row>
            <v-col sm="12" md="12" cols="12" style="padding: 0;max-width: 90%;text-align: left; margin-left: 30px;" class="screen_small pr-0">
              <label>Username</label>
              <v-text-field
                class="pt-1"
                outlined
                v-model="username"
                placeholder="Username"
                
                :rules="[(v) => !!v || $t('is_required')]"
                required
              />
            </v-col>
            <v-col sm="12" md="12" style="padding: 0;max-width: 90%;text-align: left; margin-left: 30px;" cols="12" class="screen_small pr-0">
              <label>Password</label>
              <v-text-field
                class="pt-1"
                outlined
                v-model="pwd"
                placeholder="Password"
                type="password"
                :rules="[(v) => !!v || $t('is_required')]"
                required
              />
            </v-col>
            <v-col sm="12" md="12" style="padding: 0;max-width: 90%;text-align: left; margin-left: 30px;" cols="12" class="screen_small pr-0">
              <v-checkbox label="Remember Me" v-model="rememberMe"></v-checkbox>
            </v-col>
            <v-col style="padding: 0;text-align: left; margin-left: 30px;" cols="6" sm="5">
              <v-btn
                  color="primary"
                  @click="Login"
                  style="background-color: orange;"
                  class="text-bold btn_signup  "
              >{{ $t("sign_in") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <LoadingMe
            :isLoading="showLoading"
            style="padding: 0"
            :fullPage="false"
            :myLoading="true" />
    </v-row>
  </v-container>
</template>
<script>
// const cookieJS = require("@/cookie.js");
import jwt from "jsonwebtoken";
// const intHandler = require("@/scripts/instituteHandler")
const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
import { dataStore } from "@/observable/store";
import StoreModel from "@/scripts/commerce/model/Store"
export default {
  name: "BanhJiOrderLogin",
  data: () => ({
    year: new Date().getFullYear(),
    username: '',
    pwd: '',
    rememberMe: false,
    showLoading: false,
  }),
  mounted(){
    let ruser  = localStorage.getItem('banhjicounter-remember-user')
    // window.console.log(ruser, 'ruser')
    if(ruser != null && ruser != ''){
      let user = JSON.parse(ruser)
      this.username = user.username
      this.pwd = user.password
      this.rememberMe = true
    }
  },
  components: {
    LoadingMe: () => import(`@/components/Loading`),
  },
  methods: {
    async setCookie(cname, cvalue, exdays, domain) {
			const d = new Date();
			d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
			const expires = "expires=" + d.toUTCString();
			document.cookie =
			cname + "=" + cvalue + ";" + expires + ";domain=" + domain + ";path=/";
		},
    
    async Login() {
      // const login = cookieJS.login(this.username, this.pwd);
      let d = {
        username: this.username,
        type: 'counter',
        password: this.pwd
      }
      let instituteId = ''
      let cookiedata = {}
      this.showLoading = true
      await commerceHandler.storeLogin(d).then(res=>{
        window.console.log(res, 'res back from login')
        if(res.data.data.length <= 0){
          this.showLoading = false
          alert('Username & Password incorrect')
          return
        }
        if(res.data.result.status){
          let it = res.data.result.item
          if(it.pk.indexOf('comm-regis-') == -1){
            this.showLoading = false
            alert('Username & Password incorrect')
            return
          }
          let counter =  {
            name: it.name,
            sk: it.sk,
            phone: it.phone,
            pk: it.pk,
            id: it.pk
          }
          cookiedata = {
            item: counter,
            token: res.data.result.token,
            instituteId: res.data.result.item.sk,
            creator: '',
            user: '',
            name: '',
            email: '',
            plan: '',
            accessToken: res.data.result.token
          };
          let tokenName= "banhji-counter-token" + process.env.VUE_APP_MODE
          let token = jwt.sign(cookiedata, process.env.VUE_APP_JWT_SESCRET, {
            expiresIn: 86400,
          });
          // window.console.log(token, '->token')
          this.setCookie(
            tokenName,
            token,
            365,
            ''
          );
          if(this.rememberMe){
            let user = {
              username: this.username,
              password: this.pwd
            }
            localStorage.setItem('banhjicounter-remember-user', JSON.stringify(user))
          }
          
          instituteId = res.data.result.item.sk
          dataStore.instituteId = instituteId
          localStorage.setItem('instituteId', instituteId);
          localStorage.setItem(instituteId + 'commRegister', JSON.stringify(res.data.result.item));
          let store = new StoreModel(res.data.result.store)
          store.username = ''
          store.secretCode = ''
          store.oldRegisters = []
          store.registers = []
          localStorage.setItem(instituteId + 'commStore', JSON.stringify(store));
          if(Object.keys(res.data.result.session).length > 0){
            localStorage.setItem(instituteId + 'session', JSON.stringify(res.data.result.session));
          }
          this.loadInst(instituteId)
          // 
        }else{
          this.showLoading = false
          alert('Username & Password incorrect')
        }
      })
      
    },
    async loadInst(instituteId){
      let data = {
            sk: instituteId,
            pk: instituteId,
            db: 'entity'
        }
        await commerceHandler.generalGet(data).then(res=>{
            this.showLoading = false
            let data = res.data.data
            if(data.Items.length > 0){
              // window.console.log(data, 'data')
              let inst = {
                ...data.Items[0],
                id: data.Items[0].pk
              }
              localStorage.setItem('institute', JSON.stringify(inst));
              this.showLoading = false
              this.$snotify.success('Success full')
              setTimeout(()=>{
                window.location.reload()
              }, 500)
            }
        })
      // await intHandler.getInstitute(instituteId).then(res=>{
      //   // window.console.log(res, 'res from institute')
      //   localStorage.setItem('institute', JSON.stringify(res.data.data));
      //   this.showLoading = false
      //   this.$snotify.success('Success full')
      //   setTimeout(()=>{
      //     window.location.reload()
      //   }, 500)
      // })
    }
  },
};
</script>

<style scoped>
.link_app {
  display: block;
  padding: 10px;
}

.v-btn--outlined {
  border: thin solid #d6d6d6 !important;
}

.link_app:hover {
  background: rgb(226, 226, 226);
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 2px 0;
  font-size: 20px;
  line-height: 1.428571429;
  border-radius: 15px;
  font-weight: 700;
  background-color: #000;
  color: #fff;
}

.btn-circle p {
  color: #000;
  font-size: 25px;
  font-weight: 700;
  float: left;
  text-align: left;
  position: absolute;
  top: -3px;
  right: 0;
  margin-top: 0;
}

.btn-primary p {
  color: #007bff;
}

.btn-circle.btn-primary {
  background-color: #007bff !important;
}

.img_center {
  height: 70px;
  display: block;
  margin: auto;
}

.my_row {
  margin-top: 11% !important;
}

@media (max-width: 768px) {
  .screen_small {
    margin-top: 0px;
  }

  .screen_small3 {
    margin-top: 60px;
  }

  .my_row {
    margin-top: 4% !important;
  }
}

.footer {
  color: #333;
  font-size: 16px;
  height: auto;
  float: left;
  width: 80%;
  position: fixed;
  bottom: 0;
  padding: 15px 0;
}

.text-truly {
  width: 80%;
  line-height: 40px;
  font-size: 36px;
}

@media (max-width: 768px) {
  .footer {
    color: #333;
    font-size: 16px;
    height: auto;
    float: left;
    width: 100%;
    position: relative;
    bottom: 0;
    padding: 15px 0;
  }

  .text_pwd {
    line-height: 11px !important;
  }
}

@media (max-width: 992px) {
  .screen_small {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .text-that-also {
    font-size: 18px;
    width: 100% !important;
    line-height: 25px;
  }

  .text_pwd {
    line-height: 11px !important;
  }

  .screen_small2 {
    margin-top: 200px !important;
  }

  .text-truly {
    width: 100%;
  }
}

@media (max-width: 1030px) {
  .text-that-also {
    font-size: 18px;
    width: 100% !important;
    line-height: 25px;
  }

  .text-truly {
    width: 100%;
  }

  .text_pwd {
    line-height: 11px !important;
  }
}

.line_13 {
  line-height: 13px;
}

.line_40 {
  line-height: 40px;
}

.to_access {
  font-size: 40px;
  line-height: 49px;
  /* font-family: "Niradei-Light", serif !important; */
}

.that_also {
  font-size: 21px;
  color: #fff;
  line-height: 26px;
  /* font-family: 'Niradei-Light'; */
}
</style>
